<template>
  <div
    class="block-configurator-container bg-texture-configurator px-[5%] md:px-[9%] bg-blend-lighten bg-[#F1F4F7]/95 bg-no-repeat bg-center bg-cover"
    id="configurator"
    :class="device_platform"
  >
    <div class="container">
      <div
        class="2xl:pt-[157px] 2xl:text-[50px] 2xl:leading-[40px] font-semibold uppercase md:py-[45px] md:text-[22px] text-[28px] pt-[70px] pb-[30px]"
      >
        Конфигуратор
      </div>
      <div class="model-chooser">
        <div class="nav-container gap-x-4 border-b border-[#E6E6E6] overflow-x-scroll sm:gap-x-0 lg:overflow-x-visible">
          <div
            class="nav-item whitespace-nowrap"
            v-for="(car, index) in cars"
            :key="index"
            :class="{ active: cur_car.index === car.id }"
            @click="
              cur_car = {
                index: car.id,
                name: car.id,
                color: cars_data[car.id].colors[0],
              };
              activeIndex = 0;
            "
          >
            {{ car.name }}
          </div>
        </div>
      </div>
      <div class="content-container">
        <div class="features-container">
          <div
            :class="{
              'overflow-x-scroll': cars_data[cur_car.name].colors.length > 4,
            }"
            class="colors-wrapper md:order-1 md:w-1/2 2xl:w-full md:my-[27px] 2xl:my-0 w-full md:overflow-x-visible"
          >
            <div
              class="color"
              v-for="(color, index) in cars_data[cur_car.name].colors"
              :class="[
                color,
                {
                  'relative after:absolute  after:w-2 after:h-4 after:border-white after:border-b-2 after:border-r-2 after:top-1/2 after:-translate-y-1/2 after:rotate-45 after:left-1/2 after:-translate-x-1/2':
                    index == activeIndex,
                },
              ]"
              :key="index"
              @click="
                cur_car.color = color;
                setActive(index);
              "
            ></div>
          </div>
          <div
            class="2xl:grid-cols-1 md:grid grid-flow-row auto-rows-max md:grid-cols-2 md:order-3 2xl:order-2 md:w-full hidden sm:flex md:mt-[18px] 2xl:mt-0"
          >
            <div
              class="feature-wrapper"
              v-if="cars_data[cur_car.name].dimensions"
            >
              <div class="feature-icon dimensions"></div>
              <div class="feature-data">
                <div class="feature-header">Габариты (мм)</div>
                <div class="feature-content">
                  {{ cars_data[cur_car.name].dimensions }}
                </div>
              </div>
            </div>
            <div
              class="feature-wrapper"
              v-if="cars_data[cur_car.name].transmission"
            >
              <div class="feature-icon transmission"></div>
              <div class="feature-data">
                <div class="feature-header">Трансмиссия</div>
                <div class="feature-content">
                  {{ cars_data[cur_car.name].transmission }}
                </div>
              </div>
            </div>
            <div class="feature-wrapper" v-if="cars_data[cur_car.name].engine">
              <div class="feature-icon engine"></div>
              <div class="feature-data">
                <div class="feature-header">Двигатель (л.с.)</div>
                <div
                  class="feature-content"
                  v-for="engine in cars_data[cur_car.name].engine"
                  :key="engine"
                >
                  {{ engine }}
                </div>
              </div>
            </div>
            <div
              class="feature-wrapper"
              v-if="
                cars_data[cur_car.name].fuel &&
                cars_data[cur_car.name].fuel.length
              "
            >
              <div class="feature-icon fuel"></div>
              <div class="feature-data">
                <div class="feature-header">Расход топлива (л.)</div>
                <template v-if="cars_data[cur_car.name].fuel">
                  <div
                    class="feature-content"
                    v-for="fuel in cars_data[cur_car.name].fuel"
                    :key="fuel"
                  >
                    {{ fuel }}
                  </div>
                </template>
              </div>
            </div>
            <div
              class="feature-wrapper"
              v-if="
                cars_data[cur_car.name].gear &&
                cars_data[cur_car.name].gear.length
              "
            >
              <div class="feature-icon gear"></div>
              <div class="feature-data">
                <div class="feature-header">Привод колес</div>
                <div
                  class="feature-content"
                  v-for="gear in cars_data[cur_car.name].gear"
                  :key="gear"
                >
                  {{ gear }}
                </div>
              </div>
            </div>
            <div class="feature-wrapper" v-if="cars_data[cur_car.name].warranty">
              <div class="feature-icon warranty"></div>
              <div class="feature-data">
                <div class="feature-header">Гарантия</div>
                <div class="feature-content">
                  {{ cars_data[cur_car.name].warranty }}
                </div>
              </div>
            </div>
          </div>
          <buttons--button-modal-caller modal-title="УТОЧНИТЬ НАЛИЧИЕ" modal-button="УТОЧНИТЬ" class="2xl:mt-[40px] underline text-[#002E5D] uppercase text-[15px] font-medium md:order-2 2xl:order-3 md:w-1/2 md:flex md:items-center md:justify-end cursor-pointer 2xl:w-full hidden sm:flex 2xl:justify-center">
            Уточнить наличие
          </buttons--button-modal-caller>
        </div>
        <div class="model-container">
          <div class="model-wrapper">
            <div class="car-img-wrapper">
              <div
                class="car-img relative"
                :class="[cur_car.color, cur_car.name]"
              ></div>
              <div class="desc-list grid md:grid-cols-2">
                <div
                  class="desc-item desc-item_kuzov flex items-center"
                  :class="'desc-item_kuzov-' + cur_car.name"
                >
                  <img
                    class="desc-item__line desc-item__line_kuzov"
                    src="../images/icons/line_kuzov.png"
                    alt=""
                  />
                  <img
                    src="../images/icons/trade_in.png"
                    alt=""
                    class="flex lg:hidden"
                  />
                  <p class="desc-item__text">Оцинкованный кузов</p>
                </div>
                <div
                  class="desc-item desc-item_engine flex items-center"
                  :class="'desc-item_engine-' + cur_car.name"
                  v-if="cars_data[cur_car.name].engine_more"
                >
                  <img
                    class="desc-item__line desc-item__line_engine"
                    src="../images/icons/line_engine.png"
                    alt=""
                  />
                  <img
                    src="../images/icons/trade_in.png"
                    alt=""
                    class="flex lg:hidden"
                  />
                  <p class="desc-item__text">
                    Двигатель
                    <span
                      v-for="engine in cars_data[cur_car.name].engine_more"
                      :key="engine"
                      >{{ engine }}</span
                    >
                  </p>
                </div>
                <div
                  v-if="cars_data[cur_car.name].name !== 'Preface'"
                  class="desc-item desc-item_adapt flex items-center"
                  :class="'desc-item_adapt-' + cur_car.name"
                >
                  <img
                    class="desc-item__line desc-item__line_adapt"
                    src="../images/icons/line_adapt.png"
                    alt=""
                  />
                  <img
                    src="../images/icons/trade_in.png"
                    alt=""
                    class="flex lg:hidden"
                  />
                  <p class="desc-item__text">Адаптирован под АИ-92</p>
                </div>
                <div
                  class="desc-item desc-item_fuel flex items-center"
                  :class="'desc-item_fuel-' + cur_car.name"
                  v-if="cars_data[cur_car.name].fuel_more"
                >
                  <img
                    class="desc-item__line desc-item__line_fuel"
                    src="../images/icons/line_fuel.png"
                    alt=""
                  />
                  <img
                    src="../images/icons/trade_in.png"
                    alt=""
                    class="flex lg:hidden"
                  />
                  <p class="desc-item__text">
                    Расход топлива
                    <span
                      v-for="fuel in cars_data[cur_car.name].fuel_more"
                      :key="fuel"
                      >{{ fuel }}</span
                    >
                  </p>
                </div>
                <div
                  class="desc-item desc-item_gear flex items-center"
                  :class="'desc-item_gear-' + cur_car.name"
                  v-if="cars_data[cur_car.name].gear_more"
                >
                  <img
                    class="desc-item__line desc-item__line_gear"
                    src="../images/icons/line_gear.png"
                    alt=""
                  />
                  <img
                    src="../images/icons/trade_in.png"
                    alt=""
                    class="flex lg:hidden"
                  />
                  <p class="desc-item__text">
                    <span
                      v-for="gear in cars_data[cur_car.name].gear_more"
                      :key="gear"
                      >{{ gear }}</span
                    >
                    привод колес
                  </p>
                </div>
                <buttons--button-modal-caller modal-title="УТОЧНИТЬ НАЛИЧИЕ" modal-button="УТОЧНИТЬ" class="underline text-[#002E5D] uppercase text-[15px] font-medium cursor-pointer pl-[13%] min-h-[40px] flex md:hidden">
                  Уточнить наличие
                </buttons--button-modal-caller>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useModalStore } from "@/stores/modalStore";
const activeIndex = ref(0);
const modalStore = useModalStore();

const setActive = (event) => {
  activeIndex.value = event;
};
</script>
<script>
import Mixin from "../common/mixin";
import CARS from "../common/cars-info";
import filters from "../common/finance";
import CallbackInput from "./callback-form/callback-input";
import emitter from "tiny-emitter/instance";
export default {
  name: "block-configurator",
  components: { CallbackInput },
  mixins: [Mixin, filters],
  data() {
    const valid_cars = CARS.CARS.filter((car) => car.configurable);
    const cars_data = {
      atlas: {
        name: "Atlas",
        dimensions: "4519 х 1831 х 1694",
        transmission: "6MT / 6AT",
        engine: ["2.0 - 139", "2.4 - 149", "1.8 - 184"],
        fuel: [
          "2.0MT 2WD - от 8.1",
          "2.4AT 2WD - от 8.9",
          "2.4AT 4WD - от 9.4",
          "1.8AT - от 8.5",
        ],
        gear: ["Передний", "Полный"],
        gear_more: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: ["red", "bez", "brown", "black", "white", "serebro", "gray"],
      },

      "atlas-pro": {
        name: "Atlas-PRO",
        dimensions: "4544 х 1831 х 1713",
        transmission: "7DCT",
        engine: ["1.5 - 177"],
        engine_more: ["1.5 - 177"],
        fuel: ["1.5T - от 6,8"],
        fuel_more: ["7.4"],
        gear: ["Полный"],
        gear_more: ["Полный"],
        warranty: "5 лет / 150 000 км",
        colors: ["red", "black", "gray", "serebro", "white"],
      },

      "emgrand-x7": {
        name: "Emgrand x7",
        dimensions: "4500 x 1834 x 1707",
        transmission: "6MT / 6AT",
        engine: ["1.8 – 131", "2.0 – 139"],
        fuel: false,
        gear: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: ["bez", "brown", "black", "white", "serebro", "gray"],
      },
      "emgrand-7": {
        name: "Emgrand 7",
        dimensions: "4631 x 1789 x 1479",
        transmission: "5МТ / CVT",
        engine: ["1.5 – 103", "1.8 - 133"],
        fuel: ["1.5МТ - от 7.5", "1.8MT - от 7.9", "1.8CVT - от 7.8"],
        gear: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: ["black", "white", "serebro", "gray"],
      },
      coolray: {
        name: "Coolray",
        dimensions: "4330 x 1800 x 1609",
        gear: ["Передний"],
        gear_more: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: ["bloody", "white", "silver", "grey", "cyber-blue"],
      },
      "new-coolray": {
        name: "Coolray",
        dimensions: "4380 x 1810 x 1615",
        transmission: "7DCT",
        engine: ["147 (5500)"],
        fuel: ["6,1"],
        gear: ["Передний"],
        gear_more: ["Передний"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "unicorn-grey", 
          "magnetic-grey", 
          "crystal-white", 
          "cyber-blue", 
          "bright-vermilion"],
      },
      monjaro: {
        name: "Monjaro",
        dimensions: "4770 x 1895 x 1689",
        transmission: "Автоматическая, 8-ступенчатая (8AT)",
        engine: ["238 (5500)"],
        engine_more: ["238 (5500)"],
        fuel: ["11,3"],
        fuel_more: ["11,3"],
        gear: ["Полный"],
        gear_more: ["Полный"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "ink-black",
          "emerald-blue",
          "basalt-grey",
          "aurora-silver",
          "oyster-white",
        ],
      },

      "new-tugella": {
        name: "TUGELLA",
        dimensions: "4605 х 1878 х 1643",
        transmission: "8АТ",
        engine: ["2.0Т - 238"],
        engine_more: ["2.0Т - 238"],
        gear: ["Полный"],
        gear_more: ["Полный"],
        fuel: ["8.1"],
        fuel_more: ["8.1"],
        warranty: "5 лет / 150 000 км",
        colors: ["ink-black", "light-grey", "basalt-grey", "crystal-white"],
      },

      "emgrand": {
        name: "EMGRAND",
        dimensions: "4638 x 1822 x 1460",
        transmission: "5MT",
        engine: ["1.5 - 122"],
        engine_more: ["1.5 - 122"],
        gear: ["Передний"],
        gear_more: ["Передний"],
        fuel: ["5.8"],
        fuel_more: ["5.8"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "azure-glaze", 
          "amber-gold", 
          "serenity-blue", 
          "crystal-white",
          "magnetic-grey",
          "star-grey"
          ],
      },

      "new-atlas": {
        name: "NEW ATLAS",
        dimensions: "4670 x 1900 x 1705",
        transmission: "7DCT",
        engine: ["2.0 - 200"],
        engine_more: ["2.0 - 200"],
        gear: ["Передний"],
        gear_more: ["Передний"],
        fuel: ["7.4"],
        fuel_more: ["7.4"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "moonlight-white", 
          "orion-silver", 
          "starry-blue", 
          "unbounded-black"
          ],
      },
      "belgee-x50": {
        name: "Belgee X50",
        dimensions: "4330 x 1800 x 1609",
        transmission: "7DCT",
        engine: ["1.5Т / 177"],
        engine_more: ["1.5Т / 177"],
        gear: ["Передний"],
        gear_more: ["Передний"],
        fuel: ["6.4"],
        fuel_more: ["6.4"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "white", 
        ],
      },
      "okavango": {
        name: "Okavango",
        dimensions: "4860 х 1910 х 1770",
        transmission: "7DCT",
        engine: ["2.0T / 200"],
        engine_more: ["2.0T / 200"],
        gear: ["Передний"],
        gear_more: ["Передний"],
        fuel: ["7.7"],
        fuel_more: ["7.7"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "indigo-blue", 
          "ink-black", 
          "basalt-gray", 
          "crystal-white", 
        ],
      },
      "preface": {
        name: "Preface",
        dimensions: "4825 x 1880 x 1469",
        transmission: "7DCT",
        engine: ["2.0T / 200"],
        engine_more: ["2.0T / 200"],
        gear: ["Передний"],
        gear_more: ["Передний"],
        fuel: ["7.2"],
        fuel_more: ["7.2"],
        warranty: "5 лет / 150 000 км",
        colors: [
          "basalt-grey",
          "light-grey",
          "moss-green",
          "jet-black",
          "crystal-white",
          "diamond-purple",
        ],
      },
    };
    return {
      cars: valid_cars,
      cur_car: {
        index: valid_cars[0].id,
        name: valid_cars[0].id,
        color: cars_data[valid_cars[0].id].colors[0],
      },
      cars_data: cars_data,
    };
  },
  mounted() {
    emitter.on("setActiveCar", (id) => {
      this.setActiveCar(id);
    });
  },
  beforeUnmount() {
    emitter.off("setActive");
  },
  computed: {
    imgs_array() {
      const ret = [];
      for (let i = 1; i <= this.cars[this.cur_car.index]["imgs"]; i++) {
        ret.push(i);
      }

      return ret;
    },
  },
  methods: {
    getAgreement() {
      emitter.emit("getAgreement");
    },
    setActiveCar(id) {
      this.cur_car = {
        index: id,
        name: id,
        color: this.cars_data[id].colors[0],
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.mobile {
  .model-chooser {
    font-size: 18px;
  }

  .nav-container {
    max-width: 100%;
    //padding: 0 10px;
    justify-content: space-between;
  }

  .nav-item {
    margin-right: 15px;
  }

  .nav-item:last-child {
    margin-right: unset;
  }

  .content-container {
    flex-direction: column;
    height: unset;
    padding-bottom: 0;
  }

  .background {
    height: calc(100vw * 386 / 736 + 280px);
    max-height: 500px;
  }

  .model-container {
    width: 100%;
    height: 100%;
    max-height: 500px;
    min-height: auto;
  }

  .model-description {
    left: -10px;
    top: 27%;

    @media (max-width: 400px) {
      top: 40%;
    }
  }

  .description-item {
    font-size: 16px;
    margin-bottom: 2px;
  }

  .model-wrapper {
    height: 100%;
  }

  .name-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .name-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }

  .name-first-line {
    font-size: 26px;
  }

  .name-second {
    font-size: 30px;
  }

  .car-img {
    align-self: center;
    height: calc(100vw * 386 / 736);
    min-height: 200px;

    min-width: 300px;
    margin: 0;
  }

  .colors-wrapper {
    padding-right: 10px;
    align-self: center;
    border: none;
  }

  .features-container {
    @apply 2xl:w-1/4 md:w-full md:flex-row md:flex-wrap m-0 sm:m-auto w-full max-w-full p-[20px];
  }
  .feature-wrapper {
    height: 50%;
  }

  .color {
    width: 55px;
    height: 45px;
  }
}

.tablet {
  .nav-container {
    max-width: 600px;
  }

  .nav-item {
    margin-right: 15px;
  }

  .nav-item:last-child {
    margin-right: unset;
  }

  .content-container {
    flex-direction: column;
    height: unset;
  }

  .background {
    height: 550px;
  }

  .model-container {
    width: 100%;
    padding-left: 20px;
  }

  .model-description {
    left: -10px;
    top: 430px;
  }

  .description-item {
    font-size: 20px;
  }

  .car-img {
    align-self: center;
  }

  .colors-wrapper {
    margin-right: 20px;
    padding-right: 10px;
    width: 50px;
  }

  .features-container {
    height: 400px;
    flex-wrap: wrap;
    padding: 0 20px;
    width: 100%;
    max-width: 800px;
    margin: auto;
  }

  .feature-wrapper {
    height: 50%;
  }
}

.desktop {
  @media (max-width: 1280px) {
    .colors-wrapper {
      margin-left: 30px;
      margin-right: 30px;
      padding-right: 20px;
    }
  }
}

.block-configurator-container {
  width: 100%;
  // max-width: 1920px;
  margin-top: 90px;
}

.model-chooser {
  width: 100%;
  background: transparent;
  @apply 2xl:mt-[30px];
  color: #000;
  font-size: 30px;
  text-transform: uppercase;
}

.nav-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 800px;
  justify-content: space-between;
  @apply 2xl:py-[20px];
}

.nav-item {
  cursor: pointer;
  @apply text-[15px] gap-[15px];

  &.active {
    text-decoration: underline;
    @apply 2xl:underline-offset-[30px] md:underline-offset-[8px] underline-offset-[6px];
  }
}

.content-container {
  width: 100%;
  // max-width: 1920px;
  //height: 680px;
  padding-bottom: 147px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.webp {
  .background {
    background: url("../images/configurator/specifications-fon.webp") no-repeat
      center;
    background-size: cover;
  }
}

.background {
  width: 100%;
  height: 100%;
  background: url("../images/configurator/specifications-fon.png") no-repeat
    center;
  background-size: cover;
  position: absolute;
}

.model-container,
.features-container {
  position: relative;
  //padding-top: 20px;
}

.model-container {
  display: flex;
  flex-direction: row;
  width: 72%;

  // min-height: 665px;
  // justify-content: flex-end;
  align-items: center;
  //padding-left: 100px;
  //padding-bottom: 100px;
}

.model-description {
  position: relative;
  width: 0;
  z-index: 2;
  left: -50px;
  top: 120px;
}

@media (min-width: 1700px) {
  .model-description {
    left: -70px;
    top: 300px;
  }
}

.description-item {
  white-space: nowrap;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
}

.model-wrapper {
  width: 100%;
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name-container {
  // display: flex;
  // align-items: center;
}

.name-content {
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.guarantee {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 5px;

  &__text {
    font-size: 24px;
    font-weight: 500;
    margin-right: 10px;
  }
}

.name-wrapper {
  font-weight: 500;
  margin-right: 80px;

  @media (max-width: 600px) {
    margin-right: 10px;
  }
}

.name-first-line {
  font-size: 40px;
}

.name-second {
  white-space: nowrap;
  font-size: 60px;
  margin-left: 20px;
}

// .webp {
//   .car-img {
//     &.atlas {
//       @each $color in "red", "bez", "brown", "black", "white", "gray", "serebro"
//       {
//         &.#{$color} {
//           background: url("../images/configurator/atlas/atlas-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }
//     &.atlas-pro {
//       @each $color in "blue", "red", "black", "gray", "serebro", "white"
//       {
//         &.#{$color} {
//           background: url("../images/configurator/atlas-pro/atlas-pro-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }

//     &.emgrand-7 {
//       @each $color in "black", "white", "gray", "serebro" {
//         &.#{$color} {
//           background: url("../images/configurator/emgrand-7/emgrand-7-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }

//     &.emgrand-x7 {
//       @each $color in "bez", "brown", "black", "white", "gray", "serebro" {
//         &.#{$color} {
//           background: url("../images/configurator/emgrand-x7/emgrand-x7-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }

//     &.coolray {
//       @each $color in "bloody", "white" {
//         &.#{$color} {
//           background: url("../images/configurator/coolray/coolray-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }

//     &.tugella {
//       @each $color in "ink-black", "light-grey", "basalt-grey", "crystal-white" {
//         &.#{$color} {
//           background: url("../images/configurator/tugella/tugella-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }

//     &.new-tugella {
//       @each $color in "ink-black", "light-grey", "basalt-grey", "crystal-white" {
//         &.#{$color} {
//           background: url("../images/configurator/tugella/tugella-#{$color}.webp")
//             no-repeat
//             center;
//           background-size: contain;
//         }
//       }
//     }
//   }
// }

.car-img-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  //max-width: 620px;
  left: 50%;

  transform: translateX(-50%);

  @media (max-width: 768px) {
    flex-direction: column;
    //max-width: 88%;
    @apply max-w-full;
  }
}

.car-img {
  width: 100%;
  max-width: 100%;
  height: 460px;
  align-self: flex-end;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    order: 2;
  }

  &.atlas {
    @each $color in "red", "bez", "brown", "black", "white", "gray", "serebro" {
      &.#{$color} {
        background: url("../images/configurator/atlas/atlas-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }

  &.atlas-pro {
    @each $color in "blue", "red", "black", "gray", "serebro", "white" {
      &.#{$color} {
        background: url("../images/configurator/atlas-pro/atlas-pro-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }

  &.emgrand-7 {
    @each $color in "black", "white", "gray", "serebro" {
      &.#{$color} {
        background: url("../images/configurator/emgrand-7/emgrand-7-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }

  &.emgrand-x7 {
    @each $color in "bez", "brown", "black", "white", "gray", "serebro" {
      &.#{$color} {
        background: url("../images/configurator/emgrand-x7/emgrand-x7-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }

  &.coolray {
    @each $color in "bloody", "white", "silver", "grey", "cyber-blue" {
      &.#{$color} {
        background: url("../images/configurator/coolray/coolray-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }
  &.new-coolray {
    @each $color in "unicorn-grey", "magnetic-grey", "crystal-white", "cyber-blue", "bright-vermilion" {
      &.#{$color} {
        background: url("../images/configurator/new-coolray/coolray-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }
  &.tugella {
    @each $color in "ink-black", "light-grey", "basalt-grey", "crystal-white" {
      &.#{$color} {
        background: url("../images/configurator/tugella/tugella-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }
  &.new-tugella {
    @each $color in "ink-black", "light-grey", "basalt-grey", "crystal-white" {
      &.#{$color} {
        background: url("../images/configurator/tugella/tugella-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }
  &.monjaro {
    @each $color in "ink-black", "emerald-blue", "basalt-grey", "aurora-silver",
      "oyster-white"
    {
      &.#{$color} {
        background: url("../images/configurator/monjaro/monjaro-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }
  &.emgrand {
    @each $color in 
    "azure-glaze", 
    "amber-gold", 
    "serenity-blue", 
    "crystal-white",
    "magnetic-grey",
    "star-grey"
    {
      &.#{$color} {
        background: url("../images/configurator/emgrand/emgrand-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }
  &.new-atlas {
    @each $color in 
    "moonlight-white", 
    "orion-silver", 
    "starry-blue", 
    "unbounded-black"
    {
      &.#{$color} {
        background: url("../images/configurator/new-atlas/new-atlas-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }
  &.belgee-x50 {
    &.white {
      background: url("../images/configurator/belgee-x50/belgee-x50-white.png") no-repeat center;
      background-size: contain;
    }
  }
  &.okavango {
    @each $color in 
    "indigo-blue", 
    "ink-black", 
    "basalt-gray", 
    "crystal-white"
    {
      &.#{$color} {
        background: url("../images/configurator/okavango/okavango-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }
  &.preface {
    @each $color in 
    "basalt-grey",
    "light-grey",
    "moss-green",
    "jet-black",
    "crystal-white",
    "diamond-purple",
    {
      &.#{$color} {
        background: url("../images/configurator/preface/preface-#{$color}.png")
          no-repeat
          center;
        background-size: contain;
      }
    }
  }
}

.desc-list {
  @media (max-width: 992px) {
    padding-top: 7px;
    @apply md:mt-[45px];
    //padding: 20px 0;
  }
}

.desc-item {
  position: absolute;

  @media (max-width: 992px) {
    position: static;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    //width: 210px !important;
    //border-bottom: 1px solid #000;
    transform: none !important;
  }

  @media (max-width: 600px) {
    //padding-left: 22px !important;
  }

  &:not(:last-child) {
    @media (max-width: 992px) {
      margin-bottom: 5px !important;
    }
  }

  &__text {
    z-index: 15;
    position: absolute;
    top: -30px;
    right: 50%;
    transform: translateX(50%);
    font-size: 24px;
    width: 100%;
    padding-left: 40px;
    text-align: center;

    @media (max-width: 1400px) {
      font-size: 16px;
      padding-left: 20px;
    }

    @media (max-width: 992px) {
      position: static;
      transform: none !important;
      padding: 0 !important;
      margin: 0 !important;
      text-align: left !important;
    }
  }

  &__line {
    position: relative;

    &_kuzov {
      z-index: 0;
    }

    &_engine {
      z-index: 0;
    }

    &_adap,
    &_fuel,
    &_gear {
      z-index: 10;
    }

    @media (max-width: 1400px) {
      //width: 200px;
    }

    &_gear {
      @media (max-width: 1400px) {
        //width: 240px;
      }
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  &_kuzov {
    &-tugella {
      top: 30px;
      right: 40px;

      @media (max-width: 1400px) {
        top: 45px;
        right: 95px;
      }
    }
    &-new-tugella {
      top: 56px;
      right: 90px;

      @media (max-width: 1400px) {
        top: 45px;
        right: 95px;
      }
    }
    &-monjaro {
      top: 54px;
      right: -20px;

      @media (max-width: 1400px) {
        top: 45px;
        right: 95px;
      }
    }
    &-emgrand {
      top: 54px;
      right: -20px;

      @media (max-width: 1400px) {
        top: 45px;
        right: 95px;
      }
    }
    &-new-atlas {
      top: 83px;
      right: -20px;

      @media (max-width: 1400px) {
        top: 45px;
        right: 95px;
      }
    }
    &-coolray {
      top: 30px;
      right: 110px;

      @media (max-width: 1400px) {
        top: 40px;
        right: 130px;
      }
    }
    &-new-coolray {
      top: 30px;
      right: 110px;

      @media (max-width: 1400px) {
        top: 40px;
        right: 130px;
      }
    }
    &-atlas-pro {
      top: 25px;
      right: 126px;

      @media (max-width: 1400px) {
        top: 50px;
        right: 130px;
      }
    }

    &-atlas {
      top: 30px;
      right: 40px;

      @media (max-width: 1400px) {
        top: 40px;
        right: 90px;
      }
    }
    &-belgee-x50 {
      top: 30px;
      right: 40px;

      @media (max-width: 1400px) {
        top: 40px;
        right: 90px;
      }
    }
    &-okavango {
      top: 30px;
      right: 40px;

      @media (max-width: 1400px) {
        top: 40px;
        right: 90px;
      }
    }
    &-preface {
      top: 30px;
      right: 40px;

      @media (max-width: 1400px) {
        top: 40px;
        right: 90px;
      }
    }

    @media (max-width: 1400px) {
      //width: 200px;
    }
  }

  &_engine {
    &-tugella {
      top: 120px;
      right: -150px;

      @media (max-width: 1400px) {
        right: -55px;
      }
    }

    &-new-tugella {
      top: 156px;
      right: -134px;

      @media (max-width: 1400px) {
        right: -55px;
      }
    }
    &-atlas-pro {
      top: 160px;
      right: -174px;

      @media (max-width: 1400px) {
        top: 125px;
        right: -60px;
      }
    }
    &-monjaro {
      top: 120px;
      right: -182px;

      @media (max-width: 1400px) {
        top: 125px;
        right: -100px;
      }
    }
    &-emgrand {
      top: 120px;
      right: -102px;

      @media (max-width: 1400px) {
        top: 125px;
        right: -100px;
      }
    }
    &-new-atlas {
      top: 150px;
      right: -102px;

      @media (max-width: 1400px) {
        top: 125px;
        right: -100px;
      }
    }
    &-belgee-x50 {
      top: 150px;
      right: -102px;

      @media (max-width: 1400px) {
        top: 125px;
        right: -100px;
      }
    }
    &-okavango {
      top: 125px;
      right: -102px;

      @media (max-width: 1400px) {
        top: 125px;
        right: -130px;
      }
    }
    &-preface {
      top: 150px;
      right: -102px;

      @media (max-width: 1400px) {
        top: 125px;
        right: -130px;
      }
    }

    @media (max-width: 1400px) {
      //width: 200px;
    }
  }

  &_adapt {
    .desc-item__text {
      text-align: left;

      @media (max-width: 1400px) {
        top: -7px;
        padding-left: 40px;
      }
    }

    &-tugella {
      top: 32%;
      transform: translateY(-50%);
      left: -128px;

      @media (max-width: 1400px) {
        left: -115px;
      }
    }

    &-new-tugella {
      top: 32%;
      transform: translateY(-50%);
      left: -128px;

      @media (max-width: 1400px) {
        left: -115px;
      }
    }

    &-coolray {
      top: 32%;
      transform: translateY(-50%);
      left: -80px;

      @media (max-width: 1400px) {
        left: -40px;
      }
    }

    &-new-coolray {
      top: 32%;
      transform: translateY(-50%);
      left: -80px;

      @media (max-width: 1400px) {
        left: -40px;
      }
    }

    &-atlas-pro {
      top: 32%;
      transform: translateY(-50%);
      left: -136px;

      @media (max-width: 1400px) {
        left: -115px;
      }
    }

    &-atlas {
      top: 32%;
      transform: translateY(-50%);
      left: -130px;

      @media (max-width: 1400px) {
        left: -100px;
      }
    }
    &-monjaro {
      top: 32%;
      transform: translateY(-50%);
      left: -24px;

      @media (max-width: 1400px) {
        left: -60px;
      }
    }
    &-emgrand {
      top: 32%;
      transform: translateY(-50%);
      left: -24px;

      @media (max-width: 1400px) {
        left: -60px;
      }
    }
    &-new-atlas {
      top: 16%;
      transform: translateY(-50%);
      left: -15px;

      @media (max-width: 1400px) {
        left: -90px;
      }
    }
    &-belgee-x50 {
      top: 16%;
      transform: translateY(-50%);
      left: -15px;

      @media (max-width: 1400px) {
        left: -90px;
      }
    }
    &-okavango {
      top: 16%;
      transform: translateY(-50%);
      left: -15px;

      @media (max-width: 1400px) {
        left: -50px;
      }
    }
    &-preface {
      top: 16%;
      transform: translateY(-50%);
      left: -15px;

      @media (max-width: 1400px) {
        left: -50px;
      }
    }

    @media (max-width: 1400px) {
      //width: 200px;
    }
  }

  &_fuel {
    .desc-item__text {
      top: 50px;
      padding-left: 70px;

      @media (max-width: 1400px) {
        top: 55px;
        padding-left: 28px;
      }
    }

    &-tugella {
      bottom: 0px;
      right: -135px;

      @media (max-width: 1400px) {
        bottom: 45px;
        right: -30px;
      }
    }

    &-new-tugella {
      bottom: 35px;
      right: -150px;

      @media (max-width: 1400px) {
        bottom: 45px;
        right: -30px;
      }
    }

    &-atlas-pro {
      bottom: 28px;
      right: -142px;

      @media (max-width: 1400px) {
        bottom: 30px;
        right: -40px;
      }
    }
    &-monjaro {
      bottom: 25px;
      right: -54px;

      @media (max-width: 1400px) {
        bottom: 60px;
        right: 15px;
      }
    }
    &-emgrand {
      bottom: 25px;
      right: -54px;

      @media (max-width: 1400px) {
        bottom: 60px;
        right: 15px;
      }
    }
    &-new-atlas {
      bottom: 10px;
      right: -54px;

      @media (max-width: 1400px) {
        bottom: 0px;
        right: 15px;
      }
    }

    &-belgee-x50 {
      bottom: 10px;
      right: -54px;

      @media (max-width: 1400px) {
        bottom: 0px;
        right: 15px;
      }
    }

    &-okavango {
      bottom: 10px;
      right: -54px;

      @media (max-width: 1400px) {
        bottom: 0px;
        right: 15px;
      }
    }

    &-preface {
      bottom: 10px;
      right: -54px;

      @media (max-width: 1400px) {
        bottom: 0px;
        right: 15px;
      }
    }

    @media (max-width: 1400px) {
      //width: 220px;
    }
  }

  &_gear {
    .desc-item__text {
      top: 40px;
      padding-right: 70px;
      padding-left: 0;

      @media (max-width: 1400px) {
        top: 22px;
        padding-right: 58px;
      }
    }

    &-tugella {
      bottom: -38px;
      left: 0;

      @media (max-width: 1400px) {
        bottom: 50px;
        left: 90px;
      }
    }

    &-new-tugella {
      bottom: 38px;
      left: 0;

      @media (max-width: 1400px) {
        bottom: 50px;
        left: 90px;
      }
    }

    &-coolray {
      bottom: 0px;
      left: 40px;

      @media (max-width: 1400px) {
        left: -65px;
        bottom: 20px;
      }
    }

    &-new-coolray {
      bottom: 30px;
      left: 82px;

      @media (max-width: 1400px) {
        left: 0px;
      }
    }

    &-atlas-pro {
      bottom: 30px;
      left: 0px;

      @media (max-width: 1400px) {
        bottom: 30px;
        left: 90px;
      }
    }

    &-atlas {
      bottom: 0px;
      left: -100px;

      @media (max-width: 1400px) {
        bottom: 30px;
        left: 0px;
      }
    }
    &-monjaro {
      bottom: 20px;
      left: -71px;

      @media (max-width: 1400px) {
        bottom: 30px;
        left: -90px;
      }
    }
    &-emgrand {
      bottom: 20px;
      left: -71px;

      @media (max-width: 1400px) {
        bottom: 30px;
        left: -90px;
      }
    }
    &-new-atlas {
      bottom: 40px;
      left: -71px;

      @media (max-width: 1400px) {
        bottom: 30px;
        left: -90px;
      }
    }
    &-belgee-x50 {
      bottom: 40px;
      left: -71px;

      @media (max-width: 1400px) {
        bottom: 30px;
        left: -90px;
      }
    }
    &-okavango {
      bottom: 40px;
      left: -71px;

      @media (max-width: 1400px) {
        bottom: 30px;
        left: -90px;
      }
    }
    &-preface {
      bottom: 40px;
      left: -71px;

      @media (max-width: 1400px) {
        bottom: 30px;
        left: -90px;
      }
    }

    @media (max-width: 1400px) {
      //width: 240px;
    }
  }
}

.btn-blue {
  @media (max-width: 600px) {
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

.colors-wrapper {
  // width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: center;
  gap: 10px;
  @apply 2xl:my-[50px];
}

.color {
  position: relative;
  width: 55px;
  height: 45px;
  transform: skewX(-10deg);
  margin: 0;
  cursor: pointer;
  border-radius: 5px;
  flex-shrink: 0;
  &:after {
    //content: "";
    //position: absolute;
    //left: -5px;
    //width: 21px;
    //height: 50px;
    //padding: 5px 5px;
  }
  &.red {
    background: #ff0000;
/*    background: #8d363e;*/
  }
  &.aurora-silver {
    background: #c0c0c0;
  }
  &.bloody {
    /*don`t know if there is a real differnce*/
    background: #8c363d; /*dat color from official site*/
  }
  &.bez {
    background: #c2bcae;
  }
  &.brown {
    background: #715641;
  }
  &.black {
    background: #000;
  }
  &.blue {
    background: rgb(0, 25, 106);
  }
  &.emerald-blue {
    background: #00838f;
  }
  &.white {
    background: #fff;
  }
  &.serebro {
    background: #acacac;
  }
  &.gray {
    background: #808080;
  }
  &.ink-black {
    background: #000000;
  }
  &.light-grey {
    background: #c0c0c0;
  }
  &.basalt-grey {
    background: #808080;
  }
  &.crystal-white {
    background: #ffffff;
  }
  &.oyster-white {
    background: #fff;
  }
  &.grey {
    background: #696969;
  }
  &.silver {
    background: #c0c0c0;
  }
  &.cyber-blue {
    background: #49B6D0;
  }
  &.unicorn-grey {
    background: #bdadc2;
  }
  &.magnetic-grey {
    background: #696969;
  }
  &.bright-vermilion {
    background: #FF0000;
  }
  &.azure-glaze {
    background: #50aece;
  }
  &.amber-gold {
    background: #ac9579;    
  }
  &.serenity-blue {
    background: #4a596b;
  }
  &.star-grey {
    background: #bdc9d5;
  }
  &.moonlight-white {
    background: #ffffff;
  }
  &.orion-silver {
    background: #828793;
  }
  &.starry-blue {
    background: #4c5698;
  }
  &.unbounded-black {
    background: #1a1b1d;
  }
  &.indigo-blue {
    background: #0000ff;
  }
  &.basalt-gray {
    background: #c0c0c0;
  }
  &.diamond-purple {
    background: #888aac;
  }
  &.moss-green {
    background: #718275;
  }
  &.jet-black {
    background: #000000;
  }
}

.features-container {
  display: flex;
  flex-direction: column;
  width: 50%;
/*   width: 100%;*/
}

.feature-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.feature-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;

  @each $icon in dimensions, transmission, engine, fuel, gear, warranty {
    &.#{$icon} {
      background: url("../images/configurator/specifications/#{$icon}.png")
        no-repeat
        center;
      background-size: contain;
    }
  }
}

.feature-data {
  font-size: 14px;
  font-weight: 300;
  margin: 5px 0;
}

.feature-header {
  margin: 5px 0 5px;
}

.feature-content {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}

.futures-show-button {
  width: 300px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    width: 300px;
  }

  @media (max-width: 600px) {
    margin-top: 80px;
  }
}
</style>
