<template>
  <div class="relative w-full ">
    <div
      class="cursor-pointer absolute top-4 right-4 w-8 h-8 before:content-[''] before:absolute before:w-8 before:h-px before:bg-black before:rotate-45 after:content-[''] after:absolute after:w-8 after:h-px after:bg-black after:-rotate-45 z-30"
      @click="modalStore.hideModal"
    ></div>
    <div class="bg-white rounded-md z-10 relative overflow-y-auto max-h-[768px] md:h-auto xl:max-h-[768px] 2xl:overflow-auto">
      <div class="container py-4 relative">
        <h2 class="text-center uppercase text-black font-semibold text-3xl mb-4">Подробные условия акции</h2>
        <div class="grid gap-5 text-[14px] space-y-4">
          <p><sup>1</sup> Выгода, применяемая к&nbsp;цене автомобиля, предоставляется Дилером покупателю от&nbsp;розничной цены автомобиля. Условия настоящей программы могут сочетаться с&nbsp;условиями &laquo;Программы Trade-in&raquo;.</p>
          <p><sup>2</sup> Выгода по&nbsp;программе &laquo;Трейд-ин&raquo;&nbsp;&mdash; это единовременная и&nbsp;разовая скидка по&nbsp;программе &laquo;Трейд-ин&raquo;, предоставляется дилером покупателю от&nbsp;розничной цены автомобиля, приобретаемого по&nbsp;Программе, при сдаче в&nbsp;трейд-ин автомобиля с&nbsp;пробегом. Информацию о&nbsp;брендах и&nbsp;моделях, участвующих в&nbsp;программе, уточняйте у&nbsp;официальных дилеров. Условия настоящей программы не&nbsp;могут сочетаться с&nbsp;условиями &laquo;Программы поддержки корпоративных продаж&raquo;, &laquo;Geely Лизинг&raquo;.</p>
          <p><sup>3</sup> Выгода по&nbsp;программе &laquo;Geely Finance&raquo; предоставляется при покупке нового автомобиля &laquo;Geely&raquo; в&nbsp;кредит в&nbsp;любом банке: для &laquo;Geely Atlas Pro&raquo; в&nbsp;комплектациях &laquo;Comfort&raquo; и&nbsp;&laquo;Luxury&raquo; в&nbsp;размере 200&nbsp;000&nbsp;рублей, в&nbsp;комплектациях &laquo;Flagship&raquo; и&nbsp;&laquo;Flagship+&raquo; в&nbsp;размере 220&nbsp;000&nbsp;рублей; &laquo;Geely Tugella&raquo; в&nbsp;размере 250&nbsp;000&nbsp;рублей; &laquo;Geely Monjaro&raquo; в&nbsp;размере 200&nbsp;000&nbsp;рублей; &laquo;Geely Emgrand&raquo; в&nbsp;размере 200&nbsp;000&nbsp;рублей; &laquo;Geely Ocavango&raquo; в&nbsp;размере 250&nbsp;000&nbsp;рублей; &laquo;Geely Новый Coolray&raquo; в&nbsp;размере 150&nbsp;000 рублей и&nbsp;&laquo;Geely Atlas&raquo; в&nbsp;размере 150&nbsp;000&nbsp;рублей. Условия настоящей программы не&nbsp;могут сочетаться с&nbsp;условиями &laquo;Программы поддержки корпоративных продаж&raquo;, &laquo;Geely Лизинг&raquo;.</p>
          <p><sup>4</sup> Выгода по&nbsp;программе &laquo;Лояльный Трейд-ин&raquo;&nbsp;&mdash; это единовременная и&nbsp;разовая скидка, предоставляется дилером покупателю от&nbsp;розничной цены автомобиля, приобретаемого по&nbsp;Программе, при сдаче в&nbsp;трейд-ин автомобиля марки &laquo;Geely&raquo;.</p>
          <p><sup>5</sup> Выгода по&nbsp;программе &laquo;Специальный Трейд-ин&raquo;&nbsp;&mdash; это единовременная и&nbsp;разовая скидка, предоставляется дилером покупателю от&nbsp;розничной цены автомобиля, приобретаемого по&nbsp;Программе, при сдаче в&nbsp;трейд-ин автомобиля популярной марки (список марок уточняйте в&nbsp;дилерских центрах &laquo;Geely&raquo;).</p>
          <p><sup>6</sup> При приобретении конечным покупателем Автомобиля с&nbsp;использованием кредитных средств любого Банка, по&nbsp;условиям Программы, Дилер снижает розничную цену нового Автомобиля для конечного покупателя на&nbsp;сумму не&nbsp;менее указанной. Размер суммы зависит от&nbsp;использования программы в&nbsp;сочетании с&nbsp;программой Трейд-ин. Условия настоящей программы не&nbsp;сочетаются с&nbsp;условиями программы Специальная выгода.</p>
          <p><sup>7</sup> Выгода, применяемая к&nbsp;цене автомобиля, предоставляется Дилером покупателю от&nbsp;розничной цены автомобиля. Выгода по&nbsp;программе предоставляется при приобретении конечным покупателем автомобиля за&nbsp;счет собственных средств. Условия настоящей программы не&nbsp;сочетаются с&nbsp;условиями других программ.</p>
          <p>* Выгода в&nbsp;размере 350&nbsp;000 рублей указана для автомобилей &laquo;Новый Geely Emgrand&raquo;, и&nbsp;состоит из&nbsp;выгоды при покупке в&nbsp;размере 200&nbsp;000 рублей и&nbsp;выгоды по&nbsp;программе &laquo;Лояльный/Специальный Трейд-ин&raquo; в&nbsp;размере 150&nbsp;000&nbsp;рублей.</p>
          <p>** Выгода в&nbsp;размере 300&nbsp;000 рублей указана для автомобилей &laquo;Новый Geely Coolray&raquo; и&nbsp;состоит из&nbsp;выгоды в&nbsp;размере 125&nbsp;000&nbsp;рублей, применяемой к&nbsp;цене автомобиля, выгоды при покупке комплектации в&nbsp;кредит в&nbsp;размере 150&nbsp;000 рублей и&nbsp;выгоды по&nbsp;программе &laquo;Специальный Трейд-ин&raquo; в&nbsp;размере 300&nbsp;000&nbsp;рублей.</p>
          <p>*** Выгода в&nbsp;размере 350&nbsp;000 рублей указана для автомобилей &laquo;Geely Atlas&raquo; и&nbsp;состоит из&nbsp;выгоды при покупке в&nbsp;кредит в&nbsp;размере 150&nbsp;000 рублей и&nbsp;выгоды по&nbsp;программе &laquo;Лояльный/Специальный Трейд-ин&raquo; в&nbsp;размере 200&nbsp;000&nbsp;рублей.</p>
          <p>**** Выгода в&nbsp;размере 600&nbsp;000 рублей указана для автомобилей &laquo;Geely Atlas Pro&raquo; и&nbsp;состоит из&nbsp;выгоды в&nbsp;размере 110&nbsp;000&nbsp;рублей, применяемой к&nbsp;цене автомобиля, выгоды при покупке в&nbsp;кредит в&nbsp;размере 220&nbsp;000 рублей и&nbsp;выгоды по&nbsp;программе &laquo;Трейд-ин&raquo; в&nbsp;размере 270&nbsp;000&nbsp;рублей.</p>
          <p>***** Выгода в&nbsp;размере 450&nbsp;000 рублей указана для автомобилей &laquo;Geely Okavango&raquo; и&nbsp;состоит из&nbsp;выгоды при покупке в&nbsp;кредит в&nbsp;размере 250&nbsp;000 рублей и&nbsp;выгоды по&nbsp;программе &laquo;Лояльный/Специальный Трейд-ин&raquo; в&nbsp;размере 200&nbsp;000&nbsp;рублей.</p>
          <p>****** Выгода в&nbsp;размере 680&nbsp;000 рублей указана для автомобилей &laquo;New Geely Tugella и&nbsp;состоит из&nbsp;специальной выгоды в&nbsp;размере 150&nbsp;000&nbsp;руб., выгоды при покупке в&nbsp;кредит в&nbsp;размере 250&nbsp;000 рублей и&nbsp;выгоды по&nbsp;программе &laquo;Лояльный/Специальный Трейд-ин&raquo; в&nbsp;размере 280&nbsp;000 рублей и&nbsp;доп. выгода от&nbsp;дилера &laquo;Geely Кунцево&raquo;.</p>
          <p>******* Выгода в&nbsp;размере 450&nbsp;000 рублей указана для автомобилей &laquo;Geely Monjaro&raquo; и&nbsp;состоит из&nbsp;специальной выгоды в&nbsp;размере 200&nbsp;000&nbsp;руб., выгоды при покупке в&nbsp;кредит в&nbsp;размере 200&nbsp;000 рублей и&nbsp;выгоды по&nbsp;программе &laquo;Трейд-ин&raquo; в&nbsp;размере 200&nbsp;000&nbsp;рублей.</p>
          <p>******** Выгода в&nbsp;размере 320&nbsp;000 рублей указана для автомобилей &laquo;Belgee X50&raquo; и&nbsp;состоит из&nbsp;выгоды при покупке в&nbsp;кредит в&nbsp;размере 135&nbsp;000 рублей и&nbsp;выгоды по&nbsp;программе &laquo;Трейд-ин&raquo; в&nbsp;размере 135&nbsp;000 рублей и&nbsp;выгоды по&nbsp;программе &laquo;Лояльный/Специальный Трейд-ин&raquo; в&nbsp;размере 185&nbsp;000&nbsp;рублей.</p>
          <p>Не&nbsp;оферта. Программа действует с&nbsp;01.08.2024 по&nbsp;30.09.2024&nbsp;г. Подробные условия уточняйте у&nbsp;менеджеров отдела продаж &laquo;Geely Кунцево&raquo;) по&nbsp;тел.: <a href="tel:+74959334033">+ 7&nbsp;495&nbsp;933 40&nbsp;33</a></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useModalStore } from "@/stores/modalStore";
import { useMessageStore } from "@/stores/messageStore";
import { storeToRefs } from 'pinia'
import { useDealerStore } from "@/stores/dealerStore";

const modalStore = useModalStore()
const messageStore = useMessageStore()
const dealerStore = useDealerStore();
const { modalMessage } = storeToRefs(modalStore)
const { getDealerById } = storeToRefs(dealerStore);
</script>

<style scoped>
article {
  @apply space-y-2
}
h3 {
  font-size: 16px;
  font-weight: 700;
}
h4 {
  font-size: 14px;
  font-weight: 500;
}
a[href] {
  /* text-decoration: underline; */
  color: #002E5D;
}
</style>